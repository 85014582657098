/* eslint-disable no-undef */
//
import createAuth0Client from '@auth0/auth0-spa-js';
import daggy from 'daggy';
import Future, { resolve } from 'fluture';
import reject from 'ramda/src/reject';
import * as free from './free_monad';
import { registerStaticInterpretor } from './sop';

const User = daggy.taggedSum('User', {
  Authenticate: [''],
  // ReadUserName: [''],
  ReadUserId: [''],
  ReadRemoteDbCredential: [''],
  ReadDbUrl: [''],
  Logout: [''],
});
const { Authenticate, ReadUserId, ReadRemoteDbCredential, ReadDbUrl, Logout } =
  User;

let authClient;
let userId;

// eslint-disable-next-line no-unused-vars
const stagingUserToFuture = (p) =>
  p.cata({
    Authenticate: (_) =>
      Future((_reject, resolve) => {
        (async () => {
          authClient = await createAuth0Client({
            domain: AUTH_DOMAIN,
            client_id: AUTH_CLIENT,
            scope: 'read:current_user',
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
          });

          var isAuthed = await authClient.isAuthenticated();
          if (!isAuthed) {
            try {
              // Using popup fix webkit looping login
              // TODO: Blocked pop up will straightly fail
              await authClient.getTokenWithPopup();
            } catch (reason) {
              if (reason.error == 'login_required') {
                const slashTailedUrl = `${window.location.origin}/`;
                await authClient.loginWithRedirect({
                  redirect_uri: slashTailedUrl,
                });

                reject('Required Login. Directed user to login page');
              }
            }
          }

          resolve();
        })(); // IIFE
        return () => {};
      }),
    Logout: () =>
      Future((_reject, _resolve) => {
        authClient.logout({ returnTo: window.location.origin });
        return () => {};
      }),
    ReadUserId: () =>
      Future((_reject, resolve) => {
        if (!userId) {
          authClient.getUser().then((user) => {
            userId = user['https://ana.industries/ana_id'];
            resolve(userId);
          });
        } else {
          resolve(userId);
        }
        return () => {};
      }),
    ReadRemoteDbCredential: () =>
      Future((_reject, resolve) => {
        authClient.getUser().then((user) => {
          const remoteDbUserName =
            user['https://ana.industries/cloudant_api_key'];
          const remoteDbPassword =
            user['https://ana.industries/cloudant_password'];
          resolve([remoteDbUserName, remoteDbPassword]);
        });
        return () => {};
      }),
    ReadDbUrl: () => resolve(DB_URL),
  });

// eslint-disable-next-line no-unused-vars
const localUserToFuture = (p) =>
  p.cata({
    Authenticate: (_) => resolve(),
    ReadUserId: () => resolve('tt'),
    ReadRemoteDbCredential: () => resolve([LOCAL_DB_USER, LOCAL_DB_PASSWORD]),
    ReadDbUrl: () => resolve(DB_URL),
    Logout: () => resolve(),
  });

export const userInterpretor = [
  User,
  IS_LOCAL ? localUserToFuture : stagingUserToFuture,
];
// TODO: "auto js magically"?
registerStaticInterpretor(userInterpretor);

export const authenticate = () => free.lift(Authenticate(null));
export const logout = () => free.lift(Logout(null));
export const readDbUrl = () => free.lift(ReadDbUrl(null));
export const readRemoteDbCredential = () =>
  free.lift(ReadRemoteDbCredential(null));
export const readUserId = () => free.lift(ReadUserId(null));
