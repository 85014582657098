import { createRef } from '../ref';

export const selectedFilterMode = createRef('sortField');
export const apply = createRef();

export const sfPrefix = createRef('');
export const sfSuggestions = createRef([]);
export const suggestSf = createRef();

export const sortFieldOptionValue = 'sf';
export const nodeOptionValue = 'node';
export const recentOptionValue = 'recent';
