<script>
  import {
    nodeName,
    photoCount,
    photoSources,
    goToPhotos,
    addPhotos,
  } from './gallery_store';
  import { closeModal } from '../view/view_store.js';

  $: photoOutlines = Array($photoCount);

  let cameraInput;
  function addMorePhoto() {
    cameraInput.click();
  }
  function photoTaken(e) {
    $addPhotos(e.target.files);
  }
</script>

<div
  class="fixed w-full z-10 md:max-w-md lg:max-w-lg xl:max-w-4xl bg-blue-800 border-b border-blue-500 shadow flex items-center justify-between"
>
  <button class="btn" data-test="close-button" on:click={$closeModal}>
    <i
      class="material-icons align-middle fill-current text-white hidden sm:block md:block lg:block xl:block"
      >close</i
    >
    <i
      class="material-icons align-middle fill-current text-white block sm:hidden"
      >keyboard_backspace</i
    >
  </button>

  <h2 class="text-white text-lg font-bold truncate">{$nodeName}</h2>

  <div
    class="bg-white text-blue-500 font-bold text-sm py-1 px-2 mr-2 rounded-full inline-flex items-center"
  >
    <i class="material-icons fill-current text-blue-500 text-base mr-1"
      >insert_photo
    </i>
    <span data-test="photoCount">{$photoCount}</span>
  </div>
</div>

<!-- <modal-navigator /> -->
<div>Modal Navigator supposed to be here</div>

<input
  style="display: none"
  bind:this={cameraInput}
  type="file"
  accept="image/*"
  multiple
  data-test="photo-input"
  on:change={photoTaken}
/>

<div class="flex items-center justify-between mx-4 mb-4">
  <button class="btn-icon btn-green text-sm mr-2" on:click={addMorePhoto}>
    <i class="material-icons mx-1">add_photo_alternate</i>
    <span>Add photos from...</span>
  </button>
  <button class="btn-icon btn-red text-sm">
    <!-- (click)="(gotoFilter)" -->
    <i class="material-icons mx-1">visibility</i>
    <span>View more photos from child nodes</span>
  </button>
</div>

<div class="bg-gray-200 p-2">
  <div class="flex flex-wrap mb-4">
    {#each photoOutlines as photo, i}
      <div
        class="p-1 w-1/3 lg:w-1/4 xl:w-1/6 relative"
        on:click={$goToPhotos[i]}
      >
        <!-- *ngFor="let photo of justPhotos; let i = index"
      (click)="zoom(i)" -->
        <!-- <input type="checkbox" class="absolute left-0 m-2 h-6 w-6"> -->

        <span
          class="flex absolute right-0 rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mt-1 mr-2"
          >Draft</span
        >
        <!-- *ngIf="photo.brandNew" -->
        <img
          class="object-cover h-32 w-full border border-gray-500 cursor-pointer rounded"
          src={$photoSources[i]}
          alt="yeah whatever"
        />
        <!-- *ngIf="photo.loading == false" -->
        <!-- [attr.data-test]="'image_' + i.toString()" -->
        <div>
          *ngIf="photo.loading"
          <!-- <spinner>downloading...</spinner> -->
        </div>
      </div>
    {/each}
  </div>
</div>
