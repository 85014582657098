<script>
  import { goToParentAsset, goToRootAsset } from './navigation_bar_store';

  $: isAtRoot = $goToRootAsset == undefined;
</script>

<div
  class="flex items-center bg-blue-500 rounded-full border-b-2 border-gray-400 p-2 pr-6 mb-4"
>
  <button
    tabindex="-1"
    class="cursor-pointer btn-icon btn-blue disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
    on:click={$goToParentAsset}
    disabled={isAtRoot}
  >
    <!-- (click)="navigateToParent()"
      [disabled]="atRootLevel" -->
    <i class="material-icons text-2xl align-bottom">arrow_upward</i>
  </button>

  <button
    tabindex="-1"
    class="cursor-pointer btn-icon btn-blue disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
    on:click={$goToRootAsset}
    disabled={isAtRoot}
  >
    <!-- (click)="navigateToRoot()"
      [disabled]="atRootLevel" -->
    <i class="material-icons text-2xl align-bottom">home</i>
  </button>

  <div class="mx-2 hidden sm:block md:block lg:block xl:block">
    <i class="material-icons fill-current text-gray-400 align-bottom"
      >navigate_next</i
    >
  </div>
  <div class="flex-grow">
    <select
      class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded-full shadow leading-tight focus:outline-none focus:shadow-outline"
    >
      <!-- (change)="navigatePulldown($event, pulldown)" -->
      <option value="" selected disabled>Go to ...</option>

      <option value="goToSortFieldSelectOption">
        Navigate using SortField...
      </option>
      <!-- 
        <optgroup *ngIf="ancestors?.isNotEmpty ?? false" label="Ancestors">
          <option
            *ngFor="let ancestor of ancestors; let i = index"
            [value]="ancestor.id"
            data-test="ancestor"
          >
            {{ "&#160;&#160;" | repeat: i }}L{{ i + 1 }} - {{ ancestor.name }}
          </option>
        </optgroup> -->
      <!-- 
        <optgroup *ngIf="bookmarks?.isNotEmpty ?? false" label="Bookmarks">
          <option
            *ngFor="let bookmark of bookmarks"
            [value]="bookmark.id"
            data-test="bookmark"
          >
            {{ bookmark.name }}
          </option>
        </optgroup> -->
    </select>
  </div>
  <div class="pointer-events-none text-gray-700 -ml-8">
    <i class="material-icons align-middle">keyboard_arrow_down</i>
  </div>
</div>
