<script>
  import {
    selectedPhotoSource,
    selectedPhotoIndex,
    photoCount,
    goToNext,
    goToPrevious,
  } from './gallery_store';

  let isLoading = false;

  let hasNext = true;

  let showFullScreenImage = () => console.log('Full screen');
  let startDownloadFullSizeImage = () => console.log('Download Full');

  let showingName = true;
  let currentPhotoName;
  let editName = () => console.log('edit name');

  let currentPhotoTimeStamp;
</script>

<div class="bg-gray-200">
  <div>
    <div class="bg-black relative flex items-center justify-center">
      {#if !isLoading}
        <!-- svelte-ignore a11y-missing-attribute -->
        <img
          class="object-contain custom-h-28 sm:max-h-screen md:max-h-full lg:max-h-screen xl:max-h-full"
          src={$selectedPhotoSource}
          data-test="image"
        />
      {:else}
        <div
          class="bg-black text-white custom-h-28 flex items-center justify-center"
        >
          <!-- <spinner>downloading...</spinner> -->
          downloading...
        </div>
      {/if}

      {#if $selectedPhotoIndex != null && $photoCount != null}
        <div
          class="absolute top-0 right-0 mt-2 bg-gray-700 text-gray-100 font-bold text-sm py-1 px-2 mr-2 rounded-full inline-flex items-center"
          data-test="photoIndexInfo"
        >
          {$selectedPhotoIndex}/{$photoCount}
        </div>
      {/if}

      {#if hasNext}
        <div class="flex justify-between absolute top-0 pt-12 w-full h-full">
          <button
            class="cursor-pointer mx-1 focus:outline-none"
            on:click={$goToPrevious}
          >
            <i
              class="material-icons fill-current text-gray-500 text-3xl hover:text-white focus:outline-none rounded-full bg-gray-700"
              >navigate_before</i
            >
          </button>
          <button
            class="cursor-pointer mx-1 focus:outline-none"
            on:click={$goToNext}
          >
            <i
              class="material-icons fill-current text-gray-500 text-3xl hover:text-white focus:outline-none rounded-full bg-gray-700"
              >navigate_next</i
            >
          </button>
        </div>
      {/if}
    </div>

    <div class="flex items-center justify-center bg-black">
      <button
        class="btn text-gray-400 hover:text-white"
        on:click={showFullScreenImage}
      >
        <span class="underline">view fullsize</span>
        <i class="material-icons align-middle fill-current text-gray-400"
          >open_in_new
        </i>
      </button>
      <!-- <a
        class="hidden"
        #downloadAnchor
        [href]="fullSizeImageUrl"
        [download]="currentPhotoName"
      /> -->
      <button
        class="btn text-gray-400 hover:text-white"
        on:click={startDownloadFullSizeImage}
      >
        <span class="underline">download</span>
        <i class="material-icons align-middle fill-current text-gray-400"
          >get_app
        </i>
      </button>
    </div>

    <div class="mx-3 pb-32">
      {#if showingName}
        <div>
          {#if currentPhotoName !== ''}
            <span>
              {{ currentPhotoName }}
            </span>
          {:else}
            <span class="text-gray-500"> Add a caption</span>
          {/if}
          <button class="btn" on:click={editName}>
            <i
              class="material-icons align-middle fill-current text-blue-700 hover:text-blue-500"
              >create</i
            >
          </button>
        </div>
      {/if}

      <!-- <div [style.display]="showingName ? 'none' : 'block'">
        <input
          class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 my-3 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          [value]="currentPhotoName"
          required
          autofocus
          #name
        />
        <button
          class="btn btn-red inline-flex items-center"
          (click)="(cancelEditName)"
        >
          <i class="material-icons mr-1">close</i>
          <span>Cancel</span>
        </button>
        <button
          class="btn btn-green inline-flex items-center"
          (click)="(saveName)"
        >
          <i class="material-icons mr-1">save</i>
          <span>Save</span>
        </button>
      </div> -->

      <p class="text-gray-500 text-sm">
        <!-- Created: {{ currentPhotoTimeStamp | date: "medium" }} -->
        {currentPhotoTimeStamp}
      </p>

      <!-- <p *ngIf="currentPhotoPendingDelete">Will be removed after push</p> -->
    </div>
  </div>
</div>

<!--Modal-->
<!-- <div
  #fullScreenModal
  *ngIf="showingFullscreenImage"
  class="modal opacity-100 fixed w-full h-full top-0 left-0 z-20 flex items-center justify-center"
  (click)="showingFullscreenImage = false"
>
  <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50" />

  <div class="modal-container w-full h-full shadow-lg z-30">
    <img
      class="w-full h-full object-contain"
      *ngIf="photo != null"
      [src]="imageSourceUnSafe"
    />
  </div>
</div> -->
