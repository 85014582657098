import * as R from 'ramda';

import * as db from '../database';
import * as free from '../free_monad';
import * as hid from '../hid_utils';
import * as ref from '../ref';
import { setUrl, setHid } from '../router';
import { addSop, continueSop } from '../sop';
import { viewMainPage, viewSubPage } from '../view/view_store';
import { debugLog, tapLog } from '../utils';

import AssetPage from './AssetPage.svelte';
import Information from './Information.svelte';
import { goToGalleryPage, performAddPhotos } from '../photo/photo_sop';
import * as treeStore from './tree_store';
import * as navigationBarStore from './navigation_bar_store';

export const assetEmptyPath = '/asset';
export const assetPath = '/asset/:hid';
export const informationPath = '/asset/:hid/information';

const idLens = R.lensProp('_id');

const nameLens = R.lensProp('name');
const codeLens = R.lensProp('code');
const equipmentLens = R.lensProp('equipment');
const sortFieldLens = R.lensProp('sortField');

// String -> Free [String]
// layer: PouchDB
// TODO: Should take only ID to save bandwidth
const findAttachmentDocIds = db.getMultipleFinal(R.mergeDeepRight);
const mergeInformation = R.mergeDeepRight;

const makeGoToFunction1 = R.curry((fn, a) => () => addSop(() => fn(a)));
const makeGoToFunction2 = R.curry(
  (fn, a1, a2) => () => addSop(() => fn(a1, a2))
);

// Func -> a -> [Doc] -> [(Void -> (String -> Free))]
const makeGoToFunctionByDocId = R.curry((fn, secondArg, docs) =>
  docs.map(R.pipe(R.view(idLens), makeGoToFunction2(fn, secondArg)))
);

const makeGoToAssetPageFunction = (id) => makeGoToFunction1(goToAssetPage, id);

// [Asset] -> [(Void -> (String -> Free))]
// layer: Asset, SOP
const makeGoToAssetPageFunctions = (assets) =>
  assets.map(R.pipe(R.view(idLens), makeGoToAssetPageFunction));

// [Asset] -> [(Void -> (String -> Free))]
const makePerformAddPhotoToNodeFunctions = (assets) =>
  assets.map(
    R.pipe(
      R.view(idLens),
      (id) => (blobs) => addSop(() => performAddPhotos(id, blobs))
    )
  );

// String -> Free Asset
// layer: HID, PouchDB
const findAsset = (nodeId) =>
  free
    .of(nodeId) //
    .chain(db.getFinal(R.mergeDeepRight));

// String -> Free [Asset]
// layer: HID, PouchDB
const findChildAssets = (nodeId) =>
  free
    .of(nodeId) //
    .map(hid.toChildStartKey)
    .chain(db.getMultipleFinal(R.mergeDeepRight));

// Asset -> [String]
// layer: Asset
const computeAssetLevel = R.pipe(
  R.view(idLens),
  R.split(/>./),
  R.length,
  R.flip(R.subtract)(1)
);

// [Asset] -> Free SetRef
// layer: SOP
const presentLevels = (assets) =>
  free
    .of(assets) // Free [Asset]
    .map(R.map(computeAssetLevel)) // Free [int]
    .chain(ref.setRef(treeStore.levels));

// [Asset] -> Free SetRef
// layer: SOP
const presentGoToFunctions = (parentId) =>
  free.parallelConverge([
    R.pipe(makeGoToAssetPageFunctions, ref.setRef(treeStore.goToNode)),
    R.pipe(
      makePerformAddPhotoToNodeFunctions,
      ref.setRef(treeStore.performAddPhotoToNode)
    ),
    R.pipe(
      makeGoToFunctionByDocId(goToInformationPage, parentId),
      ref.setRef(treeStore.goToInformation)
    ),
    R.pipe(
      makeGoToFunctionByDocId(goToNewAssetPage, parentId),
      ref.setRef(treeStore.goToCreateAsset)
    ),
    R.pipe(
      makeGoToFunctionByDocId(goToGalleryPage, parentId),
      ref.setRef(treeStore.goToGallery)
    ),
  ]);

// [Asset] -> Free [String]
// layer: Asset, InformationAttachment
const findAssetName = (asset) =>
  free
    .of(asset) //
    .map(R.prop('informationAttachmentId'))
    .chain(db.getFinal(mergeInformation))
    .map(R.path(['nodeInformation', 'name']));

// [Asset] -> Free SetRef
// layer: SOP
const presentLabels = (assets) =>
  free
    .of(assets) // Free [Asset]
    .map(R.map(findAssetName)) // Free [Free String]
    .chain(R.sequence(free.of)) // Free [String]
    .chain(ref.setRef(treeStore.labels));

const findAttachmentCounts = R.curry((attachmentType, assets) =>
  free
    .parallel(
      R.pluck('_id', assets)
        .map(hid.changeType(attachmentType))
        .map(findAttachmentDocIds)
    )
    .map(R.map(R.length))
);

// [Asset] -> Free SetRef
// layer: SOP, HID
const presentPhotoCounts = (assets) =>
  free
    .of(assets) //
    .chain(findAttachmentCounts(hid.photoType))
    .chain(ref.setRef(treeStore.photoCounts));

// [Asset] -> Free SetRef
// layer: SOP, HID
const presentCommentCounts = (assets) =>
  free
    .of(assets) //
    .chain(findAttachmentCounts(hid.commentType))
    .chain(ref.setRef(treeStore.commentCounts));

const goBackAssetPage = (nodeId, parentId) => () => {
  const backTo = parentId || nodeId;
  addSop(() => goToAssetPage(backTo));
};

const presentAssetHierarchy = (nodeId) =>
  free
    .of(nodeId) //
    .chain(
      R.converge(R.lift(R.concat))([
        // Turn Free Asset into Free [Asset]
        R.compose(R.map(R.of), findAsset),
        findChildAssets,
      ])
    )
    .chain(
      free.parallelConverge([
        presentLevels,
        presentLabels,
        presentPhotoCounts,
        presentCommentCounts,
        presentGoToFunctions(nodeId),
      ])
    );

// String -> Free Future
export const goToAssetPage = (nodeId) => {
  const clearedAssetHierarchyPresentation = free.sequence([
    ref.resetRef(treeStore.goToGallery),
    ref.resetRef(treeStore.goToNode),
    ref.resetRef(treeStore.levels),
    ref.resetRef(treeStore.labels),
    ref.resetRef(treeStore.photoCounts),
    ref.resetRef(treeStore.commentCounts),
  ]);

  const clearedNavigationBarPresentation = free.sequence([
    ref.resetRef(navigationBarStore.goToParentAsset),
    ref.resetRef(navigationBarStore.goToRootAsset),
  ]);

  // TODO: This does not feel functional nor good
  const presentNavigationBar = () => {
    if (R.equals(nodeId, hid.getRootId(nodeId))) {
      return clearedNavigationBarPresentation;
    } else {
      return free.sequence([
        ref.setRef(
          navigationBarStore.goToParentAsset,
          makeGoToAssetPageFunction(hid.getParentId(nodeId))
        ),
        ref.setRef(
          navigationBarStore.goToRootAsset,
          makeGoToAssetPageFunction(hid.getRootId(nodeId))
        ),
      ]);
    }
  };

  // [Free] -> Free([])
  return free.sequence([
    setUrl(assetPath, setHid(nodeId)),
    viewMainPage(AssetPage),
    clearedAssetHierarchyPresentation,
    clearedNavigationBarPresentation,
    presentAssetHierarchy(nodeId),
    presentNavigationBar(),
  ]);
};

const updatingName = R.map(
  R.set(nameLens),
  ref.getRef(treeStore.selectedAssetName)
);
const updatingCode = R.map(
  R.set(codeLens),
  ref.getRef(treeStore.selectedAssetCode)
);
const updatingEquipment = R.map(
  R.set(equipmentLens),
  ref.getRef(treeStore.selectedAssetEquipment)
);
const updatingSortField = R.map(
  R.set(sortFieldLens),
  ref.getRef(treeStore.selectedAssetSortField)
);

// Free Doc -> Free Doc
const consolidateInformation = R.pipe(
  R.ap(updatingName),
  R.ap(updatingCode),
  R.ap(updatingEquipment),
  R.ap(updatingSortField)
);

const emptyEqual = R.curry((a, b) => {
  const aEmpty = R.or(R.isNil(a), R.isEmpty(a));
  const bEmpty = R.or(R.isNil(b), R.isEmpty(b));

  if (R.and(aEmpty, bEmpty)) {
    return true;
  }
  return R.equals(a, b);
});

// Lens -> JSON -> JSON -> *
// Given the lens and two object, return the difference if the view is not the
// same. return `null` when both view is the same.
const diff = R.curry((source, target, lens) =>
  R.ifElse(
    emptyEqual(R.view(lens, source)),
    R.always(null),
    R.identity
  )(R.view(lens, target))
);

const createInformationPatch = R.curry((source, target) => {
  // ID versiones
  // Only changed prop stay
  const objectDiff = diff(source, target);
  R.map(
    (lens) => {
      target = R.set(lens, objectDiff(lens), target);
    },
    [nameLens, codeLens, equipmentLens, sortFieldLens]
  );

  return R.reject(R.isNil, target);
});

const performUpdateInformation = (informationDoc) => {
  const patch = R.lift(
    createInformationPatch(R.prop('nodeInformation', informationDoc))
  )(consolidateInformation(free.of({})));

  const newVersionInformationDoc = R.curry((doc, patch) => {
    let d = R.set(
      idLens,
      hid.generateNextVersionId('js', R.view(idLens, doc)),
      doc
    );
    return R.pipe(R.assoc('nodeInformation', patch), R.dissoc('_rev'))(d);
  });

  const n = R.lift(newVersionInformationDoc(informationDoc))(patch);

  return R.pipe(R.map(R.tap(debugLog('nn'))), R.chain(db.put))(n);
};

//TODO This isn't being saved
const performCreateInformation = () => consolidateInformation(free.of({}));

// Function -> Free Function
const continueSopFromHere = continueSop(ref.deref());

export const goToInformationPage = (parentId, nodeId) => {
  const presentSaveFunctions = (informationAttachment) =>
    R.chain(
      ref.setRef(treeStore.saveInformation),
      continueSopFromHere(() => performUpdateInformation(informationAttachment))
    );

  const returnErroIfEmtpy = R.cond([
    [R.isEmpty, R.always('Cannot be empty')],
    [R.T, R.always(null)],
  ]);

  const presentErrorIfEmtpy = R.pipe(
    returnErroIfEmtpy,
    ref.setRef(treeStore.selectedAssetNameError)
  );

  const performVerifyName = () => {
    return R.chain(
      presentErrorIfEmtpy,
      ref.getRef(treeStore.selectedAssetName)
    );
  };

  const nameVerificationPresentation = R.chain(
    ref.setRef(treeStore.verifyName),
    continueSopFromHere(performVerifyName)
  );

  const presentInformationFields = free.parallelConverge([
    R.pipe(R.view(nameLens), ref.setRef(treeStore.selectedAssetName)),
    R.pipe(R.view(codeLens), ref.setRef(treeStore.selectedAssetCode)),
    R.pipe(R.view(equipmentLens), ref.setRef(treeStore.selectedAssetEquipment)),
    R.pipe(R.view(sortFieldLens), ref.setRef(treeStore.selectedAssetSortField)),
  ]);

  const findInformationAttachment = R.pipe(
    db.getFinal(R.mergeDeepRight),
    R.chain(
      R.pipe(R.prop('informationAttachmentId'), db.getFinal(mergeInformation))
    )
  );

  const nodeInformationLens = R.lensProp('nodeInformation');

  const presentInformation = R.pipe(
    findInformationAttachment,
    R.chain(
      free.parallelConverge([
        R.pipe(R.view(nodeInformationLens), presentInformationFields),
        presentSaveFunctions,
      ])
    )
  );

  return free.sequence([
    setUrl(informationPath, setHid(nodeId)),
    viewSubPage(AssetPage, Information, goBackAssetPage(nodeId, parentId)),
    nameVerificationPresentation,
    presentInformation(nodeId),
  ]);
};

export const goToNewAssetPage = (parentId, nodeId) => {
  const clearedPresentation = free.sequence([
    ref.resetRef(treeStore.selectedAssetName),
    ref.resetRef(treeStore.selectedAssetCode),
    ref.resetRef(treeStore.selectedAssetEquipment),
    ref.resetRef(treeStore.selectedAssetSortField),
    ref.resetRef(treeStore.verifyName),
  ]);

  const saveFunctionPresentation = R.chain(
    ref.setRef(treeStore.saveInformation),
    continueSopFromHere(performCreateInformation)
  );

  return free.sequence([
    viewSubPage(AssetPage, Information, goBackAssetPage(nodeId, parentId)),
    clearedPresentation,
    saveFunctionPresentation,
  ]);
};
