import { attachmentIdFromUrlParam, nodeIdFromUrlParam } from '../hid_utils';
import { addRoute } from '../router';
import { addSop } from '../sop';
import {
  galleryPath,
  goToGalleryPage,
  goToPhotoPageFromRouter,
  photoPath,
} from './photo_sop';

export const setup = () => {
  addRoute(galleryPath, (context) => {
    addSop(() => goToGalleryPage(null, nodeIdFromUrlParam(context.params.hid)));
  });
  addRoute(photoPath, (context) => {
    addSop(() =>
      goToPhotoPageFromRouter(
        nodeIdFromUrlParam(context.params.hid),
        attachmentIdFromUrlParam(context.params.attachment_id)
      )
    );
  });
};
