<script>
  import TreeNode from './TreeNode.svelte';
  import {
    levels,
    labels,
    photoCounts,
    commentCounts,
    goToNode,
    goToInformation,
    goToGallery,
    goToCreateAsset,
    performAddPhotoToNode,
  } from './tree_store';

  $: hasLevel = $levels.length > 0;
</script>

{#if !hasLevel}
  <p>loading nodes...</p>
{/if}

{#each $levels as level, i}
  <TreeNode
    {level}
    name={$labels[i]}
    photoCount={$photoCounts[i]}
    commentCount={$commentCounts[i]}
    goToNode={$goToNode[i]}
    goToInformation={$goToInformation[i]}
    goToGallery={$goToGallery[i]}
    goToCreateAsset={$goToCreateAsset[i]}
    addPhotos={$performAddPhotoToNode[i]}
  />
{/each}
