<script>
  import { closeModal } from '../view/view_store.js';
  import {
    selectedAssetName,
    selectedAssetCode,
    selectedAssetEquipment,
    selectedAssetSortField,
    saveInformation,
    verifyName,
    selectedAssetNameError,
  } from './tree_store.js';

  let touched = false;
  const touch = () => (touched = true);

  $: title = 'Node Information';
  $: allowSubmit = touched; // && $selectedAssetNameError === null;
</script>

<div
  class="fixed w-full md:max-w-md lg:max-w-lg xl:max-w-4xl bg-blue-800 border-b border-blue-500 shadow flex items-center justify-between"
>
  <button class="btn" on:click={$closeModal}>
    <i
      class="material-icons align-middle fill-current text-white hidden sm:block md:block lg:block xl:block"
      >close</i
    >
    <i
      class="material-icons align-middle fill-current text-white block sm:hidden"
      >keyboard_backspace</i
    >
  </button>

  <h2 class="text-white text-lg font-bold truncate">{title}</h2>

  <!-- <div *ngIf="isCreating" class="w-6" /> -->
  <!-- <div #optionMenu *ngIf="!isCreating" class="relative mr-2">
    <button
      class="material-icons rounded-full cursor-pointer align-middle text-gray-400 border-2 border-gray-400 hover:text-white text-2xl focus:outline-none focus:border-red-500"
      data-test="option-menu-button"
      (click)="toggleOptionMenu(optionMenu)"
      >
      more_horiz
    </button>
    <div
      *ngIf="showingOption"
      class="absolute -ml-40 py-2 w-48 bg-white rounded-lg shadow-xl"
    >
      <button
        class="w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white"
        data-test="option-menu-move-button"
        >
        (click)="move()"
        <i class="material-icons align-bottom mr-2">redo</i>
        <span>Move Asset</span>
      </button>
      <button
        class="w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white"
        >
        (click)="isConfirmingDelete = true"
        <i class="material-icons align-bottom mr-2">delete</i>
        <span>Delete Asset</span>
      </button>
    </div>
  </div> -->
</div>

<!-- <modal-navigator *ngIf="!isCreating" /> -->

<div class="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4">
  <div class="mb-1">
    <p class="text-right">
      <!-- <span
        *ngIf="isNew"
        class="rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mr-2"
        >Draft</span
      >
      <span
        *ngIf="isMod"
        class="flex-grow-0 rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mr-2"
        >Draft</span
      >
      <span
        *ngIf="isRequestingDelete"
        class="flex-grow-0 rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mr-2"
        >Requested Delete</span
      > -->
    </p>
    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
      Name <span class="text-red-500 text-xs italic">(required)</span>
    </label>
    <div class="flex items-baseline">
      <input
        class="flex-grow-1 w-full shadow appearance-none rounded py-2 px-3 text-gray-700 mb-3 mr-1 leading-tight focus:outline-none focus:shadow-outline"
        bind:value={$selectedAssetName}
        on:input={$verifyName}
        on:input={touch}
        placeholder="Write a name"
        data-test="name"
      />
      {#if $selectedAssetNameError}
        <span
          class="flex-grow-0 rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mr-2"
          >Error</span
        >
      {/if}

      <!-- <span
        class="flex-grow-0 rounded-full bg-red-500 text-white uppercase px-2 py-1 text-xs font-bold mr-2"
        >Mod</span
      > -->
    </div>
  </div>
  <div class="mb-1">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="code">
      FLOC
    </label>
    <input
      class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
      bind:value={$selectedAssetCode}
      on:input={touch}
      data-test="code"
    />
  </div>
  <div class="mb-1">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="make">
      Equipment
    </label>
    <input
      class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
      bind:value={$selectedAssetEquipment}
      on:input={touch}
      data-test="equipment"
    />
  </div>
  <div class="mb-1">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="sortfield">
      Sort Field
    </label>
    <input
      class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
      bind:value={$selectedAssetSortField}
      on:input={touch}
      data-test="sortfield"
    />
  </div>
  <div class="flex items-center justify-between">
    <!-- svelte-ignore a11y-positive-tabindex -->
    <button
      class="btn btn-red mr-2"
      on:click={$closeModal}
      tabindex="1"
      data-test="cancel-button"
    >
      Cancel
    </button>
    <!-- (click)="(submit)" -->
    <button
      class="btn btn-green disabled:bg-gray-500 disabled:cursor-not-allowed"
      on:click={$saveInformation}
      disabled={!allowSubmit}
      tabindex="0"
      data-test="submit-button"
    >
      Ok
    </button>
  </div>
</div>

<!-- Modal for node deletion -->
<!-- <div
  *ngIf="isConfirmingDelete"
  class="modal opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center"
>
  <div
    class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    (click)="isConfirmingDelete = false"
  />

  <div
    class="modal-container bg-white w-11/12 max-h-9/10 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
  >
    <div class="m-4">
      <p>Delete the asset?</p>
      <div
        class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
        role="alert"
      >
        <p class="font-bold">Warning</p>
        <p>
          This asset has 3 child nodes. Deleting this asset will delete all 3
          child nodes too.
        </p>
      </div>

      <div class="flex items-center justify-end mt-4">
        <button class="btn btn-red mr-2" (click)="isConfirmingDelete = false">
          No
        </button>
        <button class="btn btn-green" (click)="deleteNode()">Yes</button>
      </div>
    </div>
  </div>
</div> -->
