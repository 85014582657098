import * as R from 'ramda';

export const toEndKey = (startKey) => `${startKey}\ufff0`;

//TODO: This can fail pretty hard for hid that contain more '.', like attachment
export const toDescendantStartKey = R.replace('.', '>');

export const toChildStartKey = (docId) => toDescendantStartKey(docId) + '.';

export const nodeType = Symbol('node-type');
export const photoType = Symbol('photo-type');
export const commentType = Symbol('comment-type');

const toShortType = R.cond([
  [R.equals(nodeType), R.always('n')],
  [R.equals(photoType), R.always('p')],
  [R.equals(commentType), R.always('c')],
]);

export const changeType = (newType) =>
  R.replace(/^._/, toShortType(newType) + '_');

const addType = R.curry((newType, s) => toShortType(newType) + '_' + s);
const toNodeId = addType(nodeType);

// The ultimate regex match for HID
// Match all type of HID, node, photo, comment, versioned.
const hidMatcher = R.match(
  /(._[a-z0-9.>-]*)(:|$)?([a-z0-9.]*)?(#|$)([0-9a-z]{2})?(.)?(DEL.)?([0-9a-z]{2})?/
);

const idLens = R.lensIndex(1);
const attachmentLens = R.lensIndex(3);
const versionLens = R.lensIndex(5);
// eslint-disable-next-line no-unused-vars
const deleteLens = R.lensIndex(7);
// eslint-disable-next-line no-unused-vars
const versionOwnerLens = R.lensIndex(8);

const partsToHid = R.converge(R.pipe(R.concat, R.join('>')), [
  R.pipe(R.dropLast(2)),
  R.pipe(R.takeLast(2), R.join('.'), R.of),
]);

export const getParentId = R.pipe(R.split(/[>.]/), R.dropLast(1), partsToHid);
export const getRootId = R.pipe(R.split(/[>.]/), R.take(2), partsToHid);

export const hidToUrlParam = R.pipe(
  hidMatcher,
  R.view(idLens),
  R.drop(2),
  R.replace(/[>.]/g, '_')
);
export const hidToAttachmentUrlParam = R.pipe(
  hidMatcher,
  R.view(attachmentLens),
  R.replace(/[>.]/g, '_')
);

export const nodeIdFromUrlParam = R.pipe(R.split(/_/), partsToHid, toNodeId);

export const attachmentIdFromUrlParam = R.replace(/_/, '.');

const getAttachmentPortionTokens = R.match(/:([a-z0-9.]*)(#|$)/);
const attachmentIdLens = R.lensIndex(1);

export const getAttachmentId = R.pipe(
  getAttachmentPortionTokens,
  R.view(attachmentIdLens)
);

export const nodeIdFromAttachmentId = R.pipe(
  hidMatcher,
  R.view(idLens),
  changeType(nodeType)
);

const constructAttachmentId = (ms, userId) =>
  R.join('.', [ms.toString(36), userId]);

export const constructAttachmentHid = R.curry((nodeId, ms, userId) =>
  construct(nodeId, constructAttachmentId(ms, userId))
);

export const construct = (hid, attachmentId) =>
  R.join(':', [hid, attachmentId]);

// String HID -> [String]
// const getVersionPortionTokens = R.match(/#([0-9a-z]{2}).(DEL.)?([0-9a-z]{2})/);

// String -> String
export const deriveFirstVersionId = (id) => {
  const lastIndex = R.lastIndexOf('#')(id);
  if (lastIndex > -1) {
    return R.slice(0, lastIndex, id);
  } else {
    return id;
  }
};

// String -> int
export const getVersion = R.pipe(
  hidMatcher,
  R.view(versionLens),
  R.ifElse(R.isNil, R.always(0), (v) => parseInt(v, 36))
);

// String -> String
export const generateNextVersionId = R.curry(
  (userId, id) =>
    `${deriveFirstVersionId(id)}#${(getVersion(id) + 1)
      .toString(36)
      .padStart(2, '0')}.${userId}`
);
