<script>
  import {
    showingPush,
    showingSync,
    hasInternetConnection,
    push,
    sync,
    discard,
  } from './sync_store.js';

  $: showingSyncAlert = $showingPush || $showingSync;
</script>

<!-- svelte-ignore a11y-missing-attribute -->

{#if showingSyncAlert}
  <div
    class="bg-orange-200 border-t border-b-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md"
    role="alert"
    data-test="sync-bar"
  >
    <div class="flex">
      <div class="py-1">
        <svg
          class="fill-current h-6 w-6 text-orange-500 mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
          />
        </svg>
      </div>

      {#if $showingPush}
        <div class="flex-grow" data-test="push-message">
          <p class="font-bold">
            There are drafts
            <a
              class="font-normal text-orange-600 cursor-pointer underline hover:text-orange-800"
              on:click={$discard}
              data-test="discard-link">(DISCARD ALL)</a
            >
          </p>
          {#if $hasInternetConnection}
            <p class="text-sm">
              Tip: Submit your work often. Keep your work in the cloud.
            </p>
          {:else}
            <p class="text-sm text-red-500">
              You must be online to submit changes
            </p>
          {/if}

          <!-- Show this when ANA detected new version 
          <p class="text-sm font-semibold text-red-500">
            A newer version is available. Submit your drafts soon.
          </p>-->
        </div>

        <div class="flex-grow-0">
          <button
            class="btn-icon btn-green disabled:opacity-50 disabled:bg-green-500 disabled:cursor-not-allowed"
            disabled={!$hasInternetConnection}
            on:click={$push}
          >
            <i class="material-icons fill-current text-white text-base mr-1"
              >cloud_upload</i
            >Submit
          </button>
        </div>
      {/if}

      {#if $showingSync}
        <div class="flex-grow">
          <!-- ANA detected new version but User has no drafts -->
          <p class="font-bold">
            A newer version is available. Sync now?
            {#if $hasInternetConnection}
              <p class="text-sm">Tip: Stay updated by syncing often</p>
            {:else}
              <p class="text-sm text-red-500">
                You must be online to sync from remote database
              </p>
            {/if}
          </p>
        </div>

        <div class="flex-grow-0">
          <button
            class="btn-icon btn-green disabled:opacity-50 disabled:bg-green-500 disabled:cursor-not-allowed"
            disabled={!$hasInternetConnection}
            on:click={$sync}
            data-test="sync-button"
          >
            <i class="material-icons fill-current text-white text-base mr-1"
              >sync</i
            >Sync
          </button>
        </div>
      {/if}
    </div>
  </div>
{/if}
