import daggy from 'daggy';
import { resolve } from 'fluture';
import * as free from './free_monad';
import { registerStaticInterpretor } from './sop';

const Util = daggy.taggedSum('Util', {
  Now: [''],
});
const { Now } = Util;

const utilToFuture = (p) =>
  p.cata({
    Now: (_) => {
      // `Date.now()` return the ms since Epoch. `performance.now()` give the
      // microseconds precision since load.
      // Combining these two information we can get an estimate of microsecond
      // since Epoch. Which then we can create unique microsecond ID in the
      // event of bulk upload.
      const msFromEpoch = Date.now();
      const wNow = window.performance.now();
      const final =
        msFromEpoch * 1000 + Math.floor((wNow - Math.floor(wNow)) * 1000);

      return resolve(final);
    },
  });

const utilInterpretor = [Util, utilToFuture];
registerStaticInterpretor(utilInterpretor);

export const now = () => free.lift(Now(null));

// String -> String -> console.log
export const debugLog = (title) => (x) =>
  console.log(`${title}: ${JSON.stringify(x)}`);

export const tapLog = (title) => (x) => {
  console.log(`${title}: ${JSON.stringify(x) ?? x}`);
  return x;
};
