import * as ref from '../ref';
import { addSop } from '../sop';
import { logout } from '../user';
import * as topbar from './topbar_store';

export const setupTopBar = () => {
  return ref.setRef(topbar.logout)(() => addSop(performLogout));
};

export const performLogout = () => {
  return logout();
};
