<script>
  import Popover from 'svelte-popover';

  export let level = 0;
  export let isDraft = false;
  export let name = '';
  export let sortField = '';
  export let photoCount;
  export let commentCount;
  export let goToNode;
  export let goToInformation;
  export let goToGallery;
  export let goToCreateAsset;
  export let addPhotos;

  let levelBgColor = 'bg-blue-400';
  // let levelBgColor = 'bg-blue-' + (1000 - level * 100).toString();

  let pop;
  function closePopup() {
    pop.open = false;
  }

  let cameraInput;
  function addMorePhoto() {
    cameraInput.click();
  }
  function photoTaken(e) {
    addPhotos(e.target.files);
  }
</script>

<div
  class="relative flex items-center font-semibold my-3 ml-10 shadow rounded bg-pink-100 first:ml-1 first:bg-white first:border-b-4 first:border-gray-700 first:text-xl first:shadow-none first:rounded-none"
  data-test="list"
>
  <div class="flex items-center">
    <button on:click={goToNode}>
      <!-- *ngIf="showSectionArrow(node)" (click)="sectionInto(node) -->
      <i class="material-icons -ml-12 mt-2 text-4xl">navigate_next</i>
    </button>
    <div class="text-gray-100 text-xs ml-2 p-1 rounded mr-2 {levelBgColor}">
      <!-- [ngClass]="levelColorClass(node.level)" -->
      L{level}
    </div>

    <Popover arrow={false} overlayColor="#0000" action="click" bind:this={pop}>
      <button
        class="text-green-500 hover:text-green-700 focus:outline-none focus:border-red-500"
        slot="target"
      >
        <i class="material-icons text-4xl">add_circle</i>
      </button>

      <div
        slot="content"
        class="absolute py-2 m-2 bg-white rounded-lg shadow-xl w-48 z-10 top-0 left-0"
      >
        <button
          class="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
          on:click={goToCreateAsset}
          on:click={closePopup}
        >
          <i class="material-icons text-base mr-2 align-middle"
            >account_tree
          </i>
          <span>Add Child</span>
        </button>
        <button
          class="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
          on:click={addMorePhoto}
          on:click={closePopup}
        >
          <i class="material-icons text-base mr-2 align-middle"
            >add_photo_alternate
          </i>
          <span>Add Photos from...</span>
        </button>
        <!--
    <button
      class="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
      (click)="showComment(node)"
    >
      <i class="material-icons text-base mr-2 align-middle"
        >insert_comment
      </i>
      <span>Add Comment</span>
    </button>

    <button
      class="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
      (click)="bookmark(node)"
      data-test="addBookmarkButton"
    >
      <i
        *ngIf="isBookmarked(node)"
        class="material-icons text-base mr-2 align-middle"
        >bookmark
      </i>
      <span *ngIf="isBookmarked(node)">Bookmarked!</span>

      <i
        *ngIf="!isBookmarked(node)"
        class="material-icons text-base mr-2 align-middle"
        >bookmark_border
      </i>
      <span *ngIf="!isBookmarked(node)">Bookmark</span>
    </button>

    <button
      class="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
      (click)="copyUrl(node)"
    >
      <i class="material-icons text-base mr-2 align-middle">link</i>
      <span>Copy URL</span>
    </button> -->
      </div>
    </Popover>

    {#if isDraft}
      <div class="tooltip">
        <i class="material-icons align-top mr-2 fill-current text-red-500"
          >backup</i
        ><span class="tooltiptext">Drafts</span>
      </div>
    {/if}
  </div>

  <div class="ml-2 mr-2">
    {#if sortField}
      <span class="text-gray-100 bg-yellow-500 text-xs p-1 rounded mr-1"
        >{sortField}</span
      >
    {/if}

    {#if name}
      <!-- svelte-ignore a11y-missing-attribute -->
      <!-- transition:fade -->
      <a
        class="cursor-pointer text-blue-500 underline hover:text-blue-700"
        on:click={goToInformation}
      >
        {name}
      </a>
    {/if}
  </div>

  <div class="flex items-center justify-end">
    <!-- {#if isMoving}

    {:else} -->
    <div class="mr-1">
      {#if photoCount}
        <!-- transition:fade -->
        <button
          class="bg-gray-500 hover:bg-gray-600 text-white font-bold text-sm py-1 px-2 rounded-full inline-flex items-center"
          on:click={goToGallery}
        >
          <i class="material-icons fill-current text-white text-base mr-1"
            >insert_photo
          </i>
          <span data-test="photo-count">{photoCount}</span>
        </button>
      {/if}
    </div>

    <div>
      {#if commentCount}
        <!-- transition:fade -->
        <button
          class="bg-gray-500 hover:bg-gray-600 text-white font-bold text-sm py-1 px-2 rounded-full inline-flex items-center"
        >
          <i class="material-icons fill-current text-white text-base mr-1"
            >comment
          </i>
          <span data-test="comment-count">{commentCount}</span>
        </button>
      {/if}
    </div>
    <!-- {/if} -->
    <!-- <div *ngIf="isMovingNode" class="mr-1">
      <button
        *ngIf="allowToMoveIn(node)"
        class="bg-green-500 hover:bg-green-600 text-white font-bold text-sm py-1 px-2 rounded-full inline-flex items-center"
        (click)="moveInto(node)"
        data-test="move-into-button">
        <i
          class="material-icons fill-current text-white text-base mr-1">move_to_inbox
        </i>
        Move here
      </button>

      <button
        *ngIf="!allowToMoveIn(node)"
        class="bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed text-white font-bold text-sm py-1 px-2 rounded-full inline-flex items-center"
        disabled="true"
        data-test="cannot-move-into-button">
        <i
          class="material-icons fill-current text-white text-base mr-1">not_interested
        </i>
        Not allowed
      </button>
    </div> -->
  </div>
</div>

<input
  style="display: none"
  bind:this={cameraInput}
  type="file"
  accept="image/*"
  multiple
  on:change={photoTaken}
/>
