//
import daggy from 'daggy';
import PouchDB from 'pouchdb';
import * as R from 'ramda';
import { Future } from 'fluture';
import * as free from './free_monad';
import { registerStaticInterpretor } from './sop';

const KVSetup = daggy.taggedSum('KVSetup', {
  Setup: ['url'],
});
const { Setup } = KVSetup;

const kvSetupToFuture = (p) =>
  p.cata({
    Setup: (_) =>
      Future((_, resolve) => {
        //TODO: make use of URL
        const pouch = new PouchDB('ana_kv');
        setupInterpretor(pouch);
        resolve();
        return () => {};
      }),
  });

const kvSetupInterpretor = [KVSetup, kvSetupToFuture];
const setup = (url) => free.lift(Setup(url));

const KV = daggy.taggedSum('KV', {
  Get: ['key'],
  Set: ['key', 'value'],
});
const { Get, Set } = KV;

const kvToFuture = (pouch) => (p) =>
  p.cata({
    Get: (key) =>
      Future((_, resolve) => {
        pouch
          .get(key)
          .then((doc) => resolve(doc.value))
          .catch((_) => resolve(null));
        return () => {};
      }),
    Set: (key, value) =>
      Future((reject, resolve) => {
        let doc;
        pouch
          .get(key)
          .then((d) => (doc = d))
          .catch((_) => (doc = { _id: key, value: '' }))
          .finally(() => {
            if (doc.value != value) {
              doc.value = value;
              pouch.put(doc).then(resolve).catch(reject);
            } else {
              resolve();
            }
          });
        return () => {};
      }),
  });

let interpretor;
const setupInterpretor = (pouch) => {
  interpretor = [KV, kvToFuture(pouch)];
  registerStaticInterpretor(interpretor);
  return interpretor;
};

const get = (key) => free.lift(Get(key));
const set = R.curry((key, value) => free.lift(Set(key, value)));

export { kvSetupInterpretor, setup, interpretor, setupInterpretor, get, set };
