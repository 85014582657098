/* eslint-disable no-undef */
import '../public/global.css';

import * as R from 'ramda';
import { fork, resolve } from 'fluture';

import App from './view/App.svelte';

import * as db from './database';
import * as free from './free_monad';
import { kvSetupInterpretor, setup as kvSetup } from './kv';
import { reinit as photoReinit } from './photo/photo_cache';
import { start as startRouter } from './router';
import {
  authenticate,
  readRemoteDbCredential,
  userInterpretor,
  readDbUrl,
  readUserId,
} from './user';

import { addSop } from './sop';
import { refreshSyncStatus } from './sync/sync_sop';
import { dispatch } from './interpretor';

import * as hierarchy from './hierarchy/setup';
import * as photo from './photo/setup';
import * as timeline from './timeline/timeline_sop';
import { setupTopBar } from './topbar/topbar_sop';

const startup = R.pipe(
  authenticate,
  R.chain(
    R.converge(
      R.lift((credential, dbUrl, userId) => [...credential, dbUrl, userId]),
      [readRemoteDbCredential, readDbUrl, readUserId]
    )
  ),
  R.chain((params) => {
    const [username, password, dbUrl, userId] = params;
    console.log('userId :>> ', userId);
    return free.sequence([db.setup(dbUrl, username, password), kvSetup(dbUrl)]);
  })
);

const setupInterpretor = dispatch([
  userInterpretor,
  db.pouchSetupInterpretor,
  kvSetupInterpretor,
]);
const future = startup().foldMap(setupInterpretor, resolve);

fork((e) => console.error('Setup error: ', e))((_) => {
  photoReinit();

  //TODO: Move this to last?
  addSop(() => setupTopBar());
  addSop(() => refreshSyncStatus());

  hierarchy.setup();
  photo.setup();
  timeline.setup();
  startRouter();
})(future);

// Kick start Svelte
const app = new App({
  target: document.body,
});

export default app;
