import daggy from 'daggy';
import { Future } from 'fluture';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import * as R from 'ramda';

import * as HID from './hid_utils';
import * as free from './free_monad';
import { registerDynamicInterpretorGetter } from './sop';
// import { debugLog } from './utils';

// eslint-disable-next-line no-undef
PouchDB.plugin(PouchDBFind);

const dbNameFromUrl = (url) => {
  const r = R.compose(R.prop(0), R.takeLast(1), R.split('/'))(url);
  console.log('r is ', r);
  return r;
};

const deriveLocalDBName = (url) => `local_${dbNameFromUrl(url)}`;
const deriveDraftDBName = (url) => `draft_${dbNameFromUrl(url)}`;

const PouchSetup = daggy.taggedSum('PouchSetup', {
  Setup: ['url', 'username', 'password'],
});
const { Setup } = PouchSetup;

const pouchSetupToFuture = (p) =>
  p.cata({
    Setup: (url, username, password) =>
      Future((_, resolve) => {
        const remotePouch = new PouchDB(url, {
          auth: { username: username, password: password },
        });
        const localPouch = new PouchDB(deriveLocalDBName(url));
        const draftPouch = new PouchDB(deriveDraftDBName(url));

        setupInterpretors([remotePouch, localPouch, draftPouch]);
        setupListener(remotePouch);
        setupListener(localPouch);
        setupListener(draftPouch);
        resolve();
        return () => {};
      }),
  });

const pouchSetupInterpretor = [PouchSetup, pouchSetupToFuture];
const setup = (url, username, password) =>
  free.lift(Setup(url, username, password));

const Db = daggy.taggedSum('Db', {
  Info: ['pouchToUse'],
  Get: ['pouchToUse', 'id'],
  Put: ['pouchToUse', 'doc'],
  AllDocs: ['pouchToUse', 'option'],
  CreateIndex: ['pouchToUse', 'index'],
  Find: ['pouchToUse', 'option'],
  Sync: [''],
  Push: [''],
  Reset: [''],
});
const { Info, Put, AllDocs, CreateIndex, Find, Sync, Push, Reset /*, Get*/ } =
  Db;

const remoteIndex = 0;
const localIndex = 1;
const draftIndex = 2;

const dbToFuture = (pouches) => (p) => {
  const [remotePouch, localPouch, draftPouch] = pouches;
  return p.cata({
    Info: (pouchToUse) =>
      Future((reject, resolve) => {
        pouches[pouchToUse].info().then(resolve).catch(reject);
        return () => {};
      }),
    Get: (pouchToUse, id) =>
      Future((reject, resolve) => {
        pouches[pouchToUse]
          .get(id)
          .then((doc) => resolve(doc))
          .catch((reason) => reject(reason));
        return () => {};
      }),
    Put: (pouchToUse, doc) =>
      Future((reject, resolve) => {
        pouches[pouchToUse]
          .put(doc)
          .then((rev) => {
            doc.rev = rev;
            resolve(doc);
          })
          .catch((reason) => reject(reason));
        return () => {};
      }),
    AllDocs: (pouchToUse, option) =>
      Future((reject, resolve) => {
        // console.log('all docing :>> ', JSON.stringify(option));
        pouches[pouchToUse]
          .allDocs(option)
          .then((result) => {
            setTimeout(() => resolve(result), 10 * Math.random());
          })
          .catch((reason) => reject(reason));
        return () => {};
      }),
    CreateIndex: (pouchToUse, index) =>
      Future((reject, resolve) => {
        pouches[pouchToUse]
          .createIndex(index)
          .then((result) => {
            resolve(result);
          })
          .catch((reason) => reject(reason));
        return () => {};
      }),
    Find: (pouchToUse, option) =>
      Future((reject, resolve) => {
        pouches[pouchToUse]
          .find(option)
          .then((result) => {
            resolve(result);
          })
          .catch((reason) => reject(reason));
        return () => {};
      }),
    Sync: (_) =>
      Future((reject, resolve) => {
        localPouch.replicate
          .from(remotePouch)
          .then((synced) => resolve(JSON.stringify(synced)))
          .catch(reject);
        return () => {};
      }),
    Push: (_) =>
      Future((reject, resolve) => {
        draftPouch.replicate
          .to(remotePouch)
          .then((pushed) => {
            resolve(JSON.stringify(pushed));
          })
          .catch(reject);
        return () => {};
      }),
    Reset: (_) =>
      Future((reject, resolve) => {
        draftPouch
          .destroy()
          .then((_) => {
            draftChangeListener.cancel();
            const newDraftPouch = new PouchDB(deriveDraftDBName('fakse'));

            setupInterpretors([remotePouch, localPouch, newDraftPouch]);
            draftChangeListener = setupListener(newDraftPouch);

            resolve();
          })
          .catch(reject);
        return () => {};
      }),
  });
};

let interpretors;
registerDynamicInterpretorGetter(() => interpretors);

const setupInterpretors = (pouches) => {
  interpretors = [Db, dbToFuture(pouches)];
  return interpretors;
};

// Copied from https://www.freecodecamp.org/news/javascript-debounce-example/
function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

let dbChange = () => {};
const listenToDbChange = (f) => {
  dbChange = debounce(f);
};

let draftChangeListener;
const setupListener = (draftPouch) =>
  draftPouch
    .changes({
      since: 'now',
      live: true,
      include_docs: false,
    })
    .on('change', function (_) {
      dbChange();
    })
    .on('complete', function (_) {
      dbChange();
    });

const draftPut = (doc) => free.lift(Put(draftIndex, doc));
const localAllDocs = (option) => free.lift(AllDocs(localIndex, option));
const draftAllDocs = (option) => free.lift(AllDocs(draftIndex, option));

// String -> AllDocOption
// layer: PouchDB
const makeStartEndQuery = R.curry((include_docs, startKey) => {
  return {
    startkey: startKey,
    endkey: HID.toEndKey(startKey),
    include_docs: include_docs,
  };
});

// JSON -> [JSON]
const extractAllDocsResults = R.pipe(R.prop('rows'), R.pluck('doc'));

const extractFindResults = R.prop('docs');

// String -> Free [JSON]
// Return all versions' document, sorted by their ID after concating result
// from local and draft
const getAllVersions = (id) => {
  const query = makeStartEndQuery(true, id);
  return R.pipe(
    R.converge(R.lift(R.concat), [
      R.compose(R.map(extractAllDocsResults), localAllDocs),
      R.compose(R.map(extractAllDocsResults), draftAllDocs),
    ]),
    R.map(R.sort(R.comparator((a, b) => a._id < b._id)))
  )(query);
};

// JSON -> Free
// Put a new document into the draft
const put = draftPut;

// (JSON -> JSON -> JSON) -> String -> Free JSON
const getFinal = R.curry((merger, id) =>
  R.map(R.reduce(merger, {}), getAllVersions(id))
);

const idLens = R.lensProp('_id');

// (JSON -> JSON -> JSON) -> String -> Free [JSON]
const getMultipleFinal = R.curry((merger, id) =>
  R.pipe(
    getAllVersions,
    R.map(
      R.pipe(
        R.groupWith(R.eqBy(R.pipe(R.view(idLens), HID.deriveFirstVersionId))),
        R.map(R.reduce(merger, {}))
      )
    )
  )(id)
);

const docCountLens = R.lensProp('doc_count');
const getDraftCount = () =>
  R.map(R.view(docCountLens), free.lift(Info(draftIndex)));

const getRemoteUpdateSeq = () =>
  R.map(R.view(docCountLens), free.lift(Info(remoteIndex)));

const reset = () => free.lift(Reset(null));
const push = () => free.lift(Push(null));
const sync = () => free.lift(Sync(null));

const createLocalIndex = (index) =>
  free.sequence([
    free.lift(CreateIndex(localIndex, index)),
    free.lift(CreateIndex(draftIndex, index)),
  ]);

const find = R.curry((pouchIndex, option) =>
  free.lift(Find(pouchIndex, option))
);
const findLocal = (option) =>
  R.pipe(
    R.converge(R.lift(R.concat), [
      R.compose(R.map(extractFindResults), find(localIndex)),
      R.compose(R.map(extractFindResults), find(draftIndex)),
    ]),
    R.map(R.sort(R.comparator((a, b) => a._id < b._id)))
  )(option);

export {
  pouchSetupInterpretor,
  setup,
  setupInterpretors,
  listenToDbChange,
  getAllVersions,
  put,
  getFinal,
  getMultipleFinal,
  getDraftCount,
  getRemoteUpdateSeq,
  reset,
  push,
  sync,
  createLocalIndex,
  findLocal,
};
