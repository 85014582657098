import * as R from 'ramda';

import * as db from '../database';
import * as free from '../free_monad';
import * as kv from '../kv';
import * as ref from '../ref';
import * as sync from './sync_store';
import { addSop } from '../sop';

const updateSeqKey = 'updateSeq';

db.listenToDbChange(() => addSop(refreshSyncStatus));

export const refreshSyncStatus = () => {
  const determineSync =
    // Put the complemented equality check function into a free monad
    // Using `ap`, supplied the two values, each inside a free monad
    // When both parameters are supplied, the function will evalute and produce
    // the result, inside the same free monad.
    free
      .of(R.complement(R.equals)) // Free (a -> a -> bool)
      .ap(db.getRemoteUpdateSeq())
      .ap(R.map(R.defaultTo('0'), kv.get(updateSeqKey)))
      .chain(ref.setRef(sync.showingSync));

  const determinePush = db
    .getDraftCount()
    .chain(ref.setRef(sync.showingPush))
    // As strange as it read, this means "0 is less than next input"
    .map(R.lt(0));

  const determineInternetConnection = ref.setRef(
    sync.hasInternetConnection,
    true
  );

  return free.sequence([
    determineSync,
    determinePush,
    determineInternetConnection,
    ref.setRef(sync.sync)(() => {
      addSop(performSync);
    }),
    ref.setRef(sync.push)(() => {
      addSop(performPush);
    }),
    ref.setRef(sync.discard)(() => {
      addSop(performDiscard);
    }),
  ]);
};

export const performSync = () =>
  free.sequence([
    db.sync(),
    R.chain(kv.set(updateSeqKey), db.getRemoteUpdateSeq()),
    // TODO Should go back to where the user is currently at
    // autoAddSop(() => goToAssetPage('n_aamca.mn-chpp')),
  ]);

export const performPush = () =>
  free.sequence([db.push(), db.reset(), db.sync()]);

export const performDiscard = () => {
  return db.reset();
};
