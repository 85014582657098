<script>
  import { fade } from 'svelte/transition';
  import { logout } from './topbar_store';

  let isOnline = true;
  let versionInfo = '1.0';
  let showingSetting = false;
  let username = 'converting to svelte';

  function showLog() {
    console.log('showing log');
  }

  function toggleSettingMenu() {
    showingSetting = !showingSetting;
  }
</script>

<!-- svelte-ignore a11y-missing-attribute -->
<div
  class="fixed z-20 top-0 w-screen h-12 flex bg-blue-800 text-white shadow border-b border-gray-400 px-4"
>
  <div
    class="w-1/5 sm:text-2xl text-base font-bold h-12 inline-flex items-center"
  >
    <a> <img src="favicon.png" class="h-6 mr-1" /></a>
    <a>ANA</a>
  </div>
  <!-- desktop -->
  <div
    class="hidden sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex w-3/5 text-lg font-bold h-12 items-center justify-center"
  >
    <div class="mx-4">
      <a href="./asset">
        <i class="material-icons align-middle">account_tree</i>
        Asset Tree
      </a>
    </div>
    <div class="mx-4">
      <a href="./timeline">
        <i class="material-icons align-middle fill-current">search</i>
        Find Photos
      </a>
    </div>
  </div>
  <!-- mobile -->
  <div
    class="inline-flex sm:hidden w-3/5 items-center justify-center text-xs font-bold h-12"
  >
    <div class="mx-4">
      <a>
        <i class="material-icons align-middle">account_tree</i>
        <br />
        Tree
      </a>
    </div>
    <div class="mx-4">
      <a>
        <i class="material-icons align-middle fill-current">search</i>
        <br />
        Photos
      </a>
    </div>
  </div>

  <div class="w-1/5 h-12 relative inline-flex items-center justify-end">
    {#if isOnline}
      <div><i class="material-icons align-middle mr-2">wifi</i></div>
    {:else}
      <div>
        <i class="material-icons align-middle mr-2 fill-current"
          >signal_wifi_off</i
        >
      </div>
      sortField
    {/if}

    <a class="underline cursor-pointer" on:click={showLog}>
      <span class="mr-2">{versionInfo}</span>
    </a>
    <button
      class="material-icons text-3xl focus:outline-none focus:border-red-500"
      on:click={toggleSettingMenu}
    >
      account_circle
    </button>
    {#if showingSetting}
      <div
        class="absolute right-0 top-0 mt-4 py-2 w-48 bg-white rounded-lg shadow-xl"
        transition:fade
      >
        <div class="block px-4 py-2 text-gray-800 border-b border-gray-300">
          <span class="text-sm italic text-gray-500">Logged in as: </span><br />
          <span class="font-bold"> {username}</span>
        </div>

        <a
          class="block px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white"
        >
          <i class="material-icons align-bottom mr-2">settings</i>
          <span>Settings</span>
        </a>
        <a
          class="block px-4 py-2 text-gray-800 hover:bg-gray-500 hover:text-white cursor-pointer"
          on:click={$logout}
        >
          <i class="material-icons align-bottom mr-2">exit_to_app</i>
          <span>Log out</span>
        </a>
      </div>
    {/if}
  </div>
</div>
