import * as R from 'ramda';

import * as db from '../database';
import * as free from '../free_monad';
import * as hid from '../hid_utils';
import * as ref from '../ref';
import { setUrl, setHid, setAttachmentId } from '../router';
import { addSop } from '../sop';
import { readUserId } from '../user';
import * as utils from '../utils';
import { viewSubPage } from '../view/view_store';

import { goToAssetPage } from '../hierarchy/asset_sop';
import AssetPage from '../hierarchy/AssetPage.svelte';
import Gallery from './Gallery.svelte';
import Photo from './Photo.svelte';
import * as gallery from './gallery_store';
import { retrievePhoto, putPhotoToDraft } from './photo_cache';

export const galleryPath = '/asset/:hid/photo';
export const photoPath = '/asset/:hid/photo/:attachment_id';

const idLens = R.lensProp('_id');

// String -> Free String
const constructNewAttachmentId = (nodeId) =>
  free
    .of(hid.constructAttachmentHid) //
    .ap(free.of(nodeId))
    .ap(utils.now())
    .ap(readUserId());

const cachePhoto = R.curry((blob, photoAttachment) =>
  free
    .of(photoAttachment) //
    .map(R.view(urlLens))
    .chain((url) => putPhotoToDraft(url, blob))
);

const urlLens = R.lensProp('url');
const makeUrl = R.replace(/[ ]/g, '');

const makePhotoAttachment = (nodeId) =>
  free
    .of(nodeId) //
    .chain(constructNewAttachmentId)
    .map(hid.changeType(hid.photoType))
    .map((id) => R.compose(R.set(urlLens, makeUrl(id)), R.set(idLens, id))({}));

const performAddPhotos = (nodeId, blobs) =>
  free.sequence(
    R.map((blob) =>
      makePhotoAttachment(nodeId) //
        .chain((photoAttachment) =>
          free.sequence([
            cachePhoto(blob, photoAttachment),
            db.put(photoAttachment),
          ])
        )
    )(blobs)
  );
// free.bichain(
//   (error) => free.of(`failed to cache photo: ${error}`),
//   (_) => db.put(photoAttachment)
// )(cachePhoto(blob, photoAttachment))

// PhotoAttachment -> Free ObjectURL
const photoAttachmentToObjectUrl = (photoAttachment) =>
  free
    .of(photoAttachment) //
    .map(R.prop('url'))
    .chain(retrievePhoto)
    .map(URL.createObjectURL);

// PhotoAttachment -> Number -> Free UpdateRef
const presentEachPhoto = (photoAttachment, index) =>
  free
    .of(photoAttachment) //
    .chain(photoAttachmentToObjectUrl)
    .chain(ref.updateRef(gallery.photoSources, index));

// String -> Free [ PhotoAttachment ]
const findPhotoAttachment = (id) =>
  free
    .of(id) //
    .map(hid.changeType(hid.photoType))
    .chain(db.getMultipleFinal(R.mergeDeepRight));

// [PhotoAttachment] -> Free Closure
const presentGoToPhotoFunction = (photoAttachments) =>
  free
    .of(photoAttachments) //
    .map(R.map(R.view(idLens)))
    .map(R.map((id) => () => addSop(() => goToPhotoPage(id, {}))))
    .chain(ref.setRef(gallery.goToPhotos));

// [PhotoAttachment] -> Free Future
const presentAllPhoto = (photoAttachments) =>
  free
    .of(photoAttachments) //
    .map(R.addIndex(R.map)(presentEachPhoto))
    .chain(free.parallel);

const goBackAssetPage = (nodeId, parentId) => () => {
  const backTo = parentId || nodeId;
  addSop(() => goToAssetPage(backTo));
};

// String => Free Future
const presentGallery = (nodeId) =>
  free
    .of(nodeId) //
    .chain(findPhotoAttachment)
    .chain(
      free.parallelConverge([
        R.pipe(R.length, ref.setRef(gallery.photoCount)),
        presentGoToPhotoFunction,
        presentAllPhoto,
      ])
    );

// String => Free Future
const addPhotoFunctionPresentation = (nodeId) =>
  ref.setRef(gallery.addPhotos, (blobs) =>
    addSop(() => performAddPhotos(nodeId, blobs))
  );

const goToGalleryPage = (parentId, nodeId) => {
  const clearedPresentation = free.sequence([
    ref.resetRef(gallery.photoCount),
    ref.resetRef(gallery.photoSources),
  ]);

  // [Free a] -> Free [a]
  return free.sequence([
    setUrl(galleryPath, setHid(nodeId, {})),
    viewSubPage(AssetPage, Gallery, goBackAssetPage(nodeId, parentId)),
    clearedPresentation,
    presentGallery(nodeId),
    addPhotoFunctionPresentation(nodeId),
  ]);
};

export const goToPhotoPageFromRouter = (nodeId, attachmentId) =>
  goToPhotoPage(
    hid.changeType(hid.photoType)(hid.construct(nodeId, attachmentId)),
    {}
  );

// String -> Free
const presentPhoto = (photoId) =>
  free
    .of(photoId) //
    .chain(db.getFinal(R.mergeDeepRight))
    .chain(photoAttachmentToObjectUrl)
    .chain(ref.setRef(gallery.selectedPhotoSource));

// [PhotoAttachments] -> Free
const galleryInfoPresentation = R.curry((photoId, photoAttachments) =>
  free
    .of(photoAttachments) //
    .chain((attachments) => {
      const index = R.findIndex(
        R.pipe(R.prop('_id'), R.equals(photoId)),
        attachments
      );

      const makeGoToNeighbourFunction = (i) => {
        if (i == attachments.length) return null;
        if (i < 0) return null;
        return () =>
          addSop(() => goToPhotoPage(R.prop('_id', R.nth(i, attachments)), {}));
      };

      return free.sequence([
        ref.setRef(gallery.photoCount, attachments.length),
        ref.setRef(gallery.selectedPhotoIndex, index + 1),
        ref.setRef(gallery.goToNext, makeGoToNeighbourFunction(index + 1)),
        ref.setRef(gallery.goToPrevious, makeGoToNeighbourFunction(index - 1)),
      ]);
    })
);

const goToPhotoPage = (photoId, state) => {
  const findPhotoAttachments = (_) =>
    R.pipe(hid.nodeIdFromAttachmentId, findPhotoAttachment)(photoId);
  const photoAttachments = free
    .of(state.photoAttachments) //
    .chain(R.ifElse(R.isNil, findPhotoAttachments, free.of));

  return free.sequence([
    setUrl(photoPath, setAttachmentId(photoId)),
    viewSubPage(AssetPage, Photo, goBackAssetPage(photoId, state.parentId)),
    presentPhoto(photoId),
    photoAttachments.chain(galleryInfoPresentation(photoId)),
  ]);
};

export { performAddPhotos, goToGalleryPage, goToPhotoPage };
