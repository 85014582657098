import daggy from 'daggy';
import Future from 'fluture';
import page from 'page';
import { Path } from 'path-parser';
import * as R from 'ramda';

import * as free from './free_monad';
import { hidToUrlParam, hidToAttachmentUrlParam } from './hid_utils';
import { registerStaticInterpretor } from './sop';

const addRoute = (path, func) => page(path, func);

function start() {
  page('/', () => {
    page.redirect('/asset');
  });

  page('*', () => {
    console.log('fail');
  });
  page();
}

const Navigation = daggy.taggedSum('Navigation', {
  Show: ['path', 'params'],
});
const { Show } = Navigation;

const nagivationToFuture = (p) =>
  p.cata({
    Show: (path, params) =>
      Future((_, resolve) => {
        const newPath = new Path(path).build(params);

        // TODO: Hack to prevent unlimitted history adding (because we redirect)
        // The correct thing to do here is provide show/replace/not calling
        // option to business layer.
        if (page.current == '/asset') {
          page.replace(newPath, page.prevContext, false, false);
        } else if (newPath != page.current) {
          page.show(newPath, page.prevContext, false, true);
        } else {
          console.log('skipped pushing new url to history');
        }

        resolve(newPath);
        return () => {};
      }),
  });

const setUrl = R.curry((path, params) => free.lift(Show(path, params)));

const navigationInterpretor = [Navigation, nagivationToFuture];
registerStaticInterpretor(navigationInterpretor);

const hidLens = R.lensProp('hid');
const attachmentIdLens = R.lensProp('attachment_id');
const setHid = (nodeId) =>
  R.set(hidLens, hidToUrlParam(nodeId), { hid: undefined });
const setAttachmentId = (nodeId) =>
  R.pipe(
    R.set(hidLens, hidToUrlParam(nodeId)),
    R.set(attachmentIdLens, hidToAttachmentUrlParam(nodeId))
  )({ hid: undefined, attachment_id: undefined });

export { addRoute, start, setUrl, setHid, setAttachmentId };
