//
import { createRef, createArrayRef } from '../ref';

export const nodeName = createRef('');
export const photoCount = createRef(0);
export const photoSources = createArrayRef();
export const goToPhotos = createRef([]);
export const addPhotos = createRef();

export const selectedPhotoSource = createRef('');
export const selectedPhotoIndex = createRef(0);
export const goToNext = createRef();
export const goToPrevious = createRef();
