//
import { createRef } from '../ref';

export const levels = createRef([]);
export const labels = createRef([]);
export const photoCounts = createRef([]);
export const commentCounts = createRef([]);
export const goToNode = createRef([]);
export const goToInformation = createRef([]);
export const goToGallery = createRef([]);
export const performAddPhotoToNode = createRef([]);
export const goToCreateAsset = createRef([]);
export const selectedAssetName = createRef('');
export const selectedAssetCode = createRef('');
export const selectedAssetEquipment = createRef('');
export const selectedAssetSortField = createRef('');
export const saveInformation = createRef();
export const verifyName = createRef();
export const selectedAssetNameError = createRef();
