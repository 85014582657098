import daggy from 'daggy';
import Future, { reject } from 'fluture';
import PouchDB from 'pouchdb';
import * as R from 'ramda';

import * as free from '../free_monad';
import { registerStaticInterpretor } from '../sop';

const photoCacheDBName = 'ana-photo-cache';
const photoDraftDBName = 'ana-photo-draft';

let cacheDb;
let draftDb;

export function reinit() {
  cacheDb = new PouchDB(photoCacheDBName);
  draftDb = new PouchDB(photoDraftDBName);
}

const PhotoCache = daggy.taggedSum('PhotoCache', {
  GetCache: ['id'],
  PutCache: ['id', 'data'],
  GetDraft: ['id'],
  PutDraft: ['id', 'data'],
  Download: ['filename'],
  DownloadFullSize: ['filename'],
  Upload: ['filename', 'blob'],
});
const {
  GetCache,
  PutCache,
  GetDraft,
  PutDraft,
  Download,
  DownloadFullSize,
  Upload,
} = PhotoCache;

const get = (db, id) =>
  Future((reject, resolve) => {
    db.get(id)
      .then((doc) => {
        fetch(doc.data).then((result) => result.blob().then(resolve));
      })
      .catch((error) => reject(error));
    return () => {};
  });

const put = (db, id, data) =>
  Future((_, resolve) => {
    const reader = new FileReader();
    reader.onload = async () => {
      const doc = {
        _id: id,
        data: reader.result,
      };
      await db.put(doc);

      //TODO: Resolving with argument because that's what expected
      resolve(data);
    };
    reader.readAsDataURL(data);
    return () => {};
  });

const downloadFromGcs = (filename) =>
  Future((reject, resolve) => {
    fetch(
      'https://australia-southeast1-prismatic-cider-284802.cloudfunctions.net/flight-stone',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: 'read',
          // eslint-disable-next-line no-undef
          bucket: IS_STAGING ? 'laputa-staging' : 'laputa-file',
          filename: filename,
        }),
      }
    )
      .then(async (respond) => {
        const signedUrl = await respond.text();
        const imgRespond = await fetch(signedUrl, {
          method: 'GET',
          headers: { 'Content-Type': 'img/png' },
        });
        const blob = await imgRespond.blob();
        resolve(blob);
      })
      .catch(reject);

    return () => {};
  });

const bucketPathForThumbnails = 'photo/thumbnails/';
const sizeForGallery = '_600x600';
const extensionForAllPhoto = '.png';

const thumbnailFileFormat = `${bucketPathForThumbnails}__${sizeForGallery}${extensionForAllPhoto}`;
const toThumbnailFile = (photoUrl) =>
  R.replace('__', photoUrl, thumbnailFileFormat);

const photoCacheToFuture = (p) =>
  p.cata({
    GetCache: (id) => get(cacheDb, id),
    GetDraft: (id) => get(draftDb, id),
    PutCache: (id, data) => put(cacheDb, id, data),
    PutDraft: (id, data) => put(draftDb, id, data),
    Download: (filename) => downloadFromGcs(toThumbnailFile(filename)),
    DownloadFullSize: (filename) => downloadFromGcs(filename),
    Upload: (_filename, _blob) => reject('not implemented'),
  });

const photoCacheInterpretor = [PhotoCache, photoCacheToFuture];
registerStaticInterpretor(photoCacheInterpretor);

// String -> Free Blob
const getFromCache = (id) => free.lift(GetCache(id));
// String -> Blob -> Free Blob
const putToCache = R.curry((id, data) => free.lift(PutCache(id, data)));
// String -> Free Blob
const getFromDraft = (id) => free.lift(GetDraft(id));
// String -> Blob -> Free Blob
const putToDraft = R.curry((id, data) => free.lift(PutDraft(id, data)));
// String -> Free Blob
const download = (filename) => free.lift(Download(filename));
// String -> Free Blob
const downloadFullSize = (filename) => free.lift(DownloadFullSize(filename));
// String -> Blob -> Free
const upload = R.curry((filename, blob) => free.lift(Upload(filename, blob)));

// String -> Free Blob
const downloadNewPhoto = (filename) =>
  free
    .of(filename) //
    .map(toThumbnailFile)
    .chain(download)
    .chain(putToCache(filename));

// String -> Free Blob
const retrievePhoto = (filename) =>
  free.bichain(
    () =>
      free.bichain(() => downloadNewPhoto(filename))(free.of)(
        getFromDraft(filename)
      ),
    free.of
  )(getFromCache(filename));

const retriveFullSizePhoto = (filename) =>
  free.bichain(
    () => downloadFullSize(filename),
    free.of
  )(getFromDraft(filename));

const putPhotoToDraft = putToDraft;

const uploadPhoto = (filename) =>
  free.of(filename).chain(getFromDraft).chain(upload(filename));

export {
  retrievePhoto,
  retriveFullSizePhoto,
  putPhotoToDraft,
  uploadPhoto,
  PhotoCache,
};
