import { nodeIdFromUrlParam } from '../hid_utils';
import { addRoute } from '../router';
import { addSop } from '../sop';
import {
  assetEmptyPath,
  assetPath,
  goToAssetPage,
  goToInformationPage,
  informationPath,
} from './asset_sop';

export const setup = () => {
  addRoute(assetEmptyPath, (_) => {
    // TODO: Get the root id from config
    addSop(() => goToAssetPage('n_aamca.mn-chpp'));
  });
  addRoute(assetPath, (context) => {
    addSop(() => goToAssetPage(nodeIdFromUrlParam(context.params.hid)));
  });
  addRoute(informationPath, (context) => {
    addSop(() => goToInformationPage(nodeIdFromUrlParam(context.params.hid)));
  });
};
