<script>
  import {
    apply,
    suggestSf,
    nodeOptionValue,
    recentOptionValue,
    selectedFilterMode,
    sortFieldOptionValue,
    sfPrefix,
    sfSuggestions,
  } from './filter_store';

  $: isShowingSfFilter = $selectedFilterMode == 'sortField';

  // const filterPulldownChanged = (event) => {
  //   filterMode = event.target.value;
  //   console.log('after pulldown change event :>> ', filterMode);
  // };

  const sfTextChange = (event) => {};

  const clearFilter = () => {
    console.log('clearing filter');
  };

  const applyFilter = () => {
    console.log('applying filter');
    $apply();
  };

</script>

<div
  class="flex flex-col items-center justify-center bg-gray-500 rounded-md shadow-md mb-8"
>
  <div class="inline-flex items-center my-3">
    <span class="font-bold text-white mx-2">Filter by:</span>
    <span class="relative inline-flex">
      <svg
        class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 412 232"
      >
        <path
          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
          fill="#648299"
          fill-rule="nonzero"
        />
      </svg>
      <select
        class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
        bind:value={$selectedFilterMode}
      >
        <option value={sortFieldOptionValue} selected>SortField</option>
        <option value={nodeOptionValue}>TreeNode</option>
        <option value={recentOptionValue}>Most Recent</option>
      </select>
    </span>
  </div>
  <div class="w-3/4 md:w-1/3 lg:w-1/3 xl:w-1/3">
    <input
      class="shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      class:hidden={!isShowingSfFilter}
      class:block={isShowingSfFilter}
      list="sfSuggestion"
      type="text"
      placeholder="Start typing a SortField..."
      bind:value={$sfPrefix}
      on:input={$suggestSf}
    />

    <datalist id="sfSuggestion">
      {#each $sfSuggestions as suggestion}
        <option value={suggestion} />
      {/each}
    </datalist>

    <!-- <input
      #nodeFilter
      class="shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      [style.display]="isShowingNodeFilter ? 'block' : 'none'"
      type="text"
      readonly
      placeholder="Click to browse for a node"
      (click)="isShowingTreeNav = true"
    /> -->

    <!-- <div
      class="inline-flex items-center my-3"
      [style.display]="!isFilteringSf ? 'block' : 'none'"
    >
      <span class="text-gray-800 font-semibold mr-3">Include all from:</span>
      <label class="inline-flex items-center">
        <input
          #showAllCheckbox
          type="checkbox"
          class="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
          checked
        /><span class="ml-2 text-gray-700 mr-3">Children</span>
      </label> -->
  </div>
</div>

<div class="flex items-center justify-center w-full py-4">
  <button
    class="btn btn-red mr-4 disabled:opacity-50 disabled:cursor-not-allowed"
    readonly
    on:click={clearFilter}
  >
    Clear Filters
  </button>
  <button
    class="btn btn-green disabled:opacity-50 disabled:cursor-not-allowed"
    on:click={applyFilter}
  >
    Search
  </button>
</div>
