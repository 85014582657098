import * as R from 'ramda';
import { createLocalIndex, findLocal } from '../database';

import { sequence } from '../free_monad';
import { deref, getRef, setRef } from '../ref';
import { addRoute, setUrl } from '../router';
import { addSop, continueSop } from '../sop';
// import { debugLog } from '../utils';
// import { debugLog } from '../utils';
import { viewMainPage } from '../view/view_store';
import {
  apply,
  nodeOptionValue,
  recentOptionValue,
  selectedFilterMode,
  sfPrefix,
  sfSuggestions,
  sortFieldOptionValue,
  suggestSf,
} from './filter_store';

import Timeline from './Timeline.svelte';

const timelinePath = '/timeline';
const timelineQPath = '/timeline?sf&node&recent';

const performTimelineFilter = () =>
  R.pipe(
    R.map((filterMode) => {
      const param = {};
      if (filterMode == sortFieldOptionValue) {
        return R.set(R.lensProp(sortFieldOptionValue), 'testingSF', param);
      } else if (filterMode == nodeOptionValue) {
        return R.set(R.lensProp(nodeOptionValue), 'testingNode', param);
      }
      if (filterMode == recentOptionValue) {
        return R.set(R.lensProp(recentOptionValue), '', param);
      }
    }),
    R.chain(setUrl(timelineQPath))
  )(getRef(selectedFilterMode));

const performSFSuggestion = () =>
  R.pipe(
    R.map((prefix) => {
      return {
        selector: {
          $and: [
            {
              'nodeInformation.sortField': { $gt: prefix.toUpperCase() },
            },
            {
              'nodeInformation.sortField': {
                $lt: `${prefix.toLowerCase()}\ufff0`,
              },
            },
          ],
        },
        fields: ['nodeInformation.sortField'],
        limit: 12,
      };
    }),
    R.chain(findLocal),
    R.map(R.map(R.view(R.lensPath(['nodeInformation', 'sortField'])))),
    R.chain(setRef(sfSuggestions))
  )(getRef(sfPrefix));

const presentFilter = () =>
  sequence([
    R.chain(setRef(apply), continueSop(deref(), performTimelineFilter)),
    R.chain(setRef(suggestSf), continueSop(deref(), performSFSuggestion)),
  ]);

const presentIndexCreation = () =>
  createLocalIndex({
    index: {
      fields: ['nodeInformation.sortField'],
      name: 'sortField',
    },
  });

const goToTimelinePage = () => {
  return sequence([
    setUrl(timelinePath, {}),
    viewMainPage(Timeline),
    presentFilter(),

    //todo: should be in setup
    presentIndexCreation(),
  ]);
};

export const setup = () => {
  addRoute(timelinePath, (_) => {
    addSop(() => goToTimelinePage());
  });
};
